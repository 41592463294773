@keyframes fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

body {
  background-color: black;
  margin: 0;
  overflow: hidden;
}

.left,
.right {
  position: absolute;
  animation: fade 3s;
  animation-iteration-count: 1;
  opacity: 0;
}

.left {
  padding-right: 100px;
}

.right {
  padding-left: 100px;
}

@media (max-width: 640px) {
  .left {
    padding-right: 50px;
  }

  .right {
    padding-left: 50px;
  }
}
